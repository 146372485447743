// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./iPwGlWLhW-0.js";

const serializationHash = "framer-c3xwW"

const variantClassNames = {b_mQS30cY: "framer-v-qy1au8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {U_5FWB8bN: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({brandLink, height, id, tap, width, ...props}) => { return {...props, bHNtfrHOt: brandLink ?? props.bHNtfrHOt, mXx3peZOC: tap ?? props.mXx3peZOC} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;brandLink?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, bHNtfrHOt, mXx3peZOC, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "b_mQS30cY", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapgv4a1g = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (mXx3peZOC) {const res = await mXx3peZOC(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={bHNtfrHOt} openInNewTab={false}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-qy1au8", className, classNames)} framer-11x59tj`} data-framer-name={"Brand"} data-highlight layoutDependency={layoutDependency} layoutId={"b_mQS30cY"} onTap={onTapgv4a1g} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-gzf24h"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"oczMXbmhM"}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 36) - 0) - 36) / 2))) + 0)), pixelHeight: 20, pixelWidth: 58, src: "https://framerusercontent.com/images/VIYwudQoEWx6Cvhgy2Mt86jrsJE.svg"}} className={"framer-hzyhxw"} layoutDependency={layoutDependency} layoutId={"FqPiYhCTK"}/></motion.div></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-c3xwW.framer-11x59tj, .framer-c3xwW .framer-11x59tj { display: block; }", ".framer-c3xwW.framer-qy1au8 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-c3xwW .framer-gzf24h { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-c3xwW .framer-hzyhxw { flex: none; height: 36px; position: relative; width: 108px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-c3xwW.framer-qy1au8, .framer-c3xwW .framer-gzf24h { gap: 0px; } .framer-c3xwW.framer-qy1au8 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-c3xwW.framer-qy1au8 > :first-child, .framer-c3xwW .framer-gzf24h > :first-child { margin-left: 0px; } .framer-c3xwW.framer-qy1au8 > :last-child, .framer-c3xwW .framer-gzf24h > :last-child { margin-right: 0px; } .framer-c3xwW .framer-gzf24h > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 108
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"bHNtfrHOt":"brandLink","mXx3peZOC":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameriPwGlWLhW: React.ComponentType<Props> = withCSS(Component, css, "framer-c3xwW") as typeof Component;
export default FrameriPwGlWLhW;

FrameriPwGlWLhW.displayName = "Brand Logo";

FrameriPwGlWLhW.defaultProps = {height: 36, width: 108};

addPropertyControls(FrameriPwGlWLhW, {bHNtfrHOt: {title: "Brand Link", type: ControlType.Link}, mXx3peZOC: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FrameriPwGlWLhW, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})